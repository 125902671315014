var exports = {};

exports = function (hljs) {
  var KEYWORDS = {
    keyword: // JS keywords
    "in if for while finally new do return else break catch instanceof throw try this " + "switch continue typeof delete debugger case default function var with " + // LiveScript keywords
    "then unless until loop of by when and or is isnt not it that otherwise from to til fallthrough super " + "case default function var void const let enum export import native " + "__hasProp __extends __slice __bind __indexOf",
    literal: // JS literals
    "true false null undefined " + // LiveScript literals
    "yes no on off it that void",
    built_in: "npm require console print module global window document"
  };
  var JS_IDENT_RE = "[A-Za-z$_](?:-[0-9A-Za-z$_]|[0-9A-Za-z$_])*";
  var TITLE = hljs.inherit(hljs.TITLE_MODE, {
    begin: JS_IDENT_RE
  });
  var SUBST = {
    className: "subst",
    begin: /#\{/,
    end: /}/,
    keywords: KEYWORDS
  };
  var SUBST_SIMPLE = {
    className: "subst",
    begin: /#[A-Za-z$_]/,
    end: /(?:\-[0-9A-Za-z$_]|[0-9A-Za-z$_])*/,
    keywords: KEYWORDS
  };
  var EXPRESSIONS = [hljs.BINARY_NUMBER_MODE, {
    className: "number",
    begin: "(\\b0[xX][a-fA-F0-9_]+)|(\\b\\d(\\d|_\\d)*(\\.(\\d(\\d|_\\d)*)?)?(_*[eE]([-+]\\d(_\\d|\\d)*)?)?[_a-z]*)",
    relevance: 0,
    starts: {
      end: "(\\s*/)?",
      relevance: 0
    } // a number tries to eat the following slash to prevent treating it as a regexp

  }, {
    className: "string",
    variants: [{
      begin: /'''/,
      end: /'''/,
      contains: [hljs.BACKSLASH_ESCAPE]
    }, {
      begin: /'/,
      end: /'/,
      contains: [hljs.BACKSLASH_ESCAPE]
    }, {
      begin: /"""/,
      end: /"""/,
      contains: [hljs.BACKSLASH_ESCAPE, SUBST, SUBST_SIMPLE]
    }, {
      begin: /"/,
      end: /"/,
      contains: [hljs.BACKSLASH_ESCAPE, SUBST, SUBST_SIMPLE]
    }, {
      begin: /\\/,
      end: /(\s|$)/,
      excludeEnd: true
    }]
  }, {
    className: "regexp",
    variants: [{
      begin: "//",
      end: "//[gim]*",
      contains: [SUBST, hljs.HASH_COMMENT_MODE]
    }, {
      // regex can't start with space to parse x / 2 / 3 as two divisions
      // regex can't start with *, and it supports an "illegal" in the main mode
      begin: /\/(?![ *])(\\\/|.)*?\/[gim]*(?=\W|$)/
    }]
  }, {
    begin: "@" + JS_IDENT_RE
  }, {
    begin: "``",
    end: "``",
    excludeBegin: true,
    excludeEnd: true,
    subLanguage: "javascript"
  }];
  SUBST.contains = EXPRESSIONS;
  var PARAMS = {
    className: "params",
    begin: "\\(",
    returnBegin: true,

    /* We need another contained nameless mode to not have every nested
    pair of parens to be called "params" */
    contains: [{
      begin: /\(/,
      end: /\)/,
      keywords: KEYWORDS,
      contains: ["self"].concat(EXPRESSIONS)
    }]
  };
  return {
    aliases: ["ls"],
    keywords: KEYWORDS,
    illegal: /\/\*/,
    contains: EXPRESSIONS.concat([hljs.COMMENT("\\/\\*", "\\*\\/"), hljs.HASH_COMMENT_MODE, {
      className: "function",
      contains: [TITLE, PARAMS],
      returnBegin: true,
      variants: [{
        begin: "(" + JS_IDENT_RE + "\\s*(?:=|:=)\\s*)?(\\(.*\\))?\\s*\\B\\->\\*?",
        end: "\\->\\*?"
      }, {
        begin: "(" + JS_IDENT_RE + "\\s*(?:=|:=)\\s*)?!?(\\(.*\\))?\\s*\\B[-~]{1,2}>\\*?",
        end: "[-~]{1,2}>\\*?"
      }, {
        begin: "(" + JS_IDENT_RE + "\\s*(?:=|:=)\\s*)?(\\(.*\\))?\\s*\\B!?[-~]{1,2}>\\*?",
        end: "!?[-~]{1,2}>\\*?"
      }]
    }, {
      className: "class",
      beginKeywords: "class",
      end: "$",
      illegal: /[:="\[\]]/,
      contains: [{
        beginKeywords: "extends",
        endsWithParent: true,
        illegal: /[:="\[\]]/,
        contains: [TITLE]
      }, TITLE]
    }, {
      begin: JS_IDENT_RE + ":",
      end: ":",
      returnBegin: true,
      returnEnd: true,
      relevance: 0
    }])
  };
};

export default exports;